import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/window_cleaning.jpg'
import IMG2 from '../../assets/carpet_cleaning.jpg'
import IMG3 from '../../assets/exterior_cleaning.jpg'
import IMG4 from '../../assets/gutter_clearing.jpg'
import IMG5 from '../../assets/office_cleaning.jpg'
import IMG6 from '../../assets/end_of_tenancy_cleaning.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Window Cleaning',
    body: 'We use both traditional and modern techniques to ensure that all your internal & external windows, frames and ledges are cleaned thoroughly. Our heated reach & wash systems cut through dirt leaving windows & frames gleaming. In addition we clean solar panels, cladding, conservatories, fascias, soffits & shop signs. Regular cleaning schedules can be arranged along with adhoc cleans on residential & commercial properties.',
    demo: 'https://www.youtube.com/watch?v=RJa-xSctaPg&list=PLky4T9wkgZd9d5DqnW66TwuniAPj74kJJ&index=18'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Carpet & Upholstery Cleaning',
    body: 'The safest and most effective carpet cleaning service that exists today is using a hot water extraction machine, alongside a pretreatment & stain protection. This is applied before and after the treatment respectively. All properties are inspected & measured before quotation. Shark-tooth detailing for your carpets and stripe detailing for your upholstery leaves your soft furnishings well-groomed and photo ready!',
    demo: 'https://www.youtube.com/watch?v=N1ajrJZjmRc&list=PLky4T9wkgZd-wmKD6ghqviqrBWW8FjAQv&index=5&ab_channel=B%26NCleaningSpecialists'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Exterior Cleaning',
    body: 'Whether you need us to clean driveways, wooden decking, stonework, roofs or patio you can be sure we will meet any requirements and maintain exceptional standards. Our cleaning services are tailored to each individual surface or substrate. This ensures the material remains safe and undamaged throughout the whole cleaning service, whether it is with our commercial grade pressure washing equipment or chemical aided soft washing techniques.',
    demo: 'https://www.youtube.com/shorts/1m9x4Jy_mWM'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Gutter Clearance',
    body: 'Moss, leaves and other debris can leave your gutters and downpipes blocked. This can result in serious damage to your property. We use equipment which allows us to work from ground level and keep your gutters clear. Our gutter vacuum gets to those areas which ladders cannot, in addition we check these areas with an inspection camera kit.',
    demo: 'https://www.youtube.com/watch?v=9Tdn7P2XfJ4&list=PLky4T9wkgZd_Ap3e6zOSfPK5A1wjei-yU&index=9'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Commercial & Office Cleaning',
    body: "We tailor our contract cleaning services around you. That means you'll have a dedicated, experienced and fully insured contact for all your cleaning requirements. With B&N Cleaning Specialists, clients know exactly what to expect - professionalism, efficiency and exceptional results. If you require any communal areas to be cleaned and maintained, please contact us.",
    demo: 'https://www.youtube.com/watch?v=gqspKLg87XA&list=PLky4T9wkgZd9d5DqnW66TwuniAPj74kJJ&index=4&ab_channel=B%26NCleaningSpecialists'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Pre / End Of Tenancy Cleaning',
    body: 'Our pre/end of tenancy cleans are carried out by experienced cleaners that leave your property clean, sanitised & detailed. We offer a specified deep clean that can be discussed where extras can be added for an additional cost. We offer 30% off carpet cleaning if you book a pre/end of tenancy clean.',
    demo: 'https://www.youtube.com/watch?v=b2NBE2FiWRQ&list=PLky4T9wkgZd-wmKD6ghqviqrBWW8FjAQv&index=16'
  }
]

function Portfolio() {
  return (
    <section id='portfolio'>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, body, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item__image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <p>{body}</p>
                <div className="portfolio__item__cta">
                  <a href={demo} className='btn btn-primary' target="_blank" rel="noopener noreferrer" aria-label="Click here to view cleaning service demo." alt="Cleaning service">Demo</a>
                  <a href="#contact" className='btn btn-secondary' aria-label="Click here to book a cleaning service now.">Book now!</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio