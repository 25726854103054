import React from 'react'
import './about.css'
import ME from '../../assets/about_us.jpg'
import {FaHandsWash} from 'react-icons/fa'
import {GiWindow, GiVacuumCleaner} from 'react-icons/gi'

function About() {
  return (
    <section id='about'>
      <h2>About us</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me__image">
            <img src={ME} alt="Window cleaning" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <div className="about__card__icon">
                <GiWindow className='about__icon'/>
              </div>
              <h5>Window<br />cleaning</h5>
              <small>15+ Years experience</small>
            </article>

            <article className='about__card'>
              <GiVacuumCleaner className='about__icon'/>
              <h5>Interior<br />cleaning</h5>
              <small>15+ Years experience</small>
            </article>

            <article className='about__card'>
              <FaHandsWash className='about__icon'/>
              <h5>Commercial<br />cleaning</h5>
              <small>10+ Years experience</small>
            </article>
          </div>

          <p>
            We have vast experience dealing with both residential &amp; commercial cleaning services. All services are offered at a competitive rate by our experienced staff, offered with honest advice &amp; simple no obligation pricing.<br /><br />
            Be prepared to have your windows and doors cleaned by a company that truly cares about quality.
          </p>

          <a href="#contact" className='btn btn-primary' aria-label="Click here to contact us for cleaning services.">Get in touch</a>
        </div>
      </div>
    </section>
  )
}

export default About