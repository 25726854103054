import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

function Services() {
  return (
    <section id='services'>
      <h2>Services</h2>
     <div className='container services__container'>
        {/* EXTERIOR CLEANING START */}
        <article className='service'>
          <div className="service__head">
            <h3>Exterior Cleaning</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Stone cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Pressure washing</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Roof cleaning &amp; Biocide treatment</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Moss removal</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Soft washing</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Gutter cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Steam cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Bin cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Solar panel cleaning</p>
            </li>
          </ul>
        </article>
        {/* EXTERIOR CLEANING END */}
        {/* INTERIOR CLEANING START */}
        <article className='service'>
          <div className="service__head">
            <h3>Interior Cleaning</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Carpet &amp; Upholstery cleaning </p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Deep clean</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>End of tenancy cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Shark-tooth carpet detailing</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Lighting cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Woodwork cleaning and polishing</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Interior window cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Stripe upholstery detailing</p>
            </li>
          </ul>
        </article>
        {/* INTERIOR CLEANING END */}
        {/*COMMERCIAL CLEANING START */}
        <article className='service'>
          <div className="service__head">
            <h3>Commercial Cleaning</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Communal block cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Estate maintenance</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Office cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Antiviral fogging</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Covid 19 cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Specialist surface cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Security camera cleaning</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Washing facilities</p>
            </li>
            <li>
              <BiCheck className='service__list__icon'/>
              <p>Suspended ceilings</p>
            </li>
          </ul>
        </article>
        {/*COMMERCIAL CLEANING END */}
     </div>
    </section>
  )
}

export default Services