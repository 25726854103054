import React from 'react'
import {BsYoutube, BsInstagram} from 'react-icons/bs'
import {FaTiktok, FaFacebookF} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.instagram.com/bncleaningspecialists/?hl=en-gb" className="text-copy" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our instagram." alt="Instagram icon" ><BsInstagram /></a>
        <a href="https://www.youtube.com/channel/UCcIg39TshWRq9QeH1XEgYjA?app=desktop" className="text-copy" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our youtube." alt="Youtube icon"><BsYoutube /></a>
        <a href="https://www.facebook.com/bncleaningspecialists" className="text-copy" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our facebook."alt="Facebook icon"><FaFacebookF /></a>
        <a href="https://www.tiktok.com/@bncleaningspecialists?_t=8Ugs11qZ2Vp&_r=1" className="text-copy" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our tiktok." alt="Tiktok icon" ><FaTiktok /></a>
    </div>
  )
}

export default HeaderSocials