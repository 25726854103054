import React from 'react'
import './contact.css'
import {MdOutlineMailOutline} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsTelephone} from 'react-icons/bs'
import emailjs from 'emailjs-com';
import {useRef} from 'react';

const Contact = () => {
  
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_q4rjs9r', 'template_mqpxeid', e.target, 'ARlf5iC0wVkpxT-1x')
      
    e.target.reset();
  }

  return (
    <section id='contact'>
      <h2>Contact us</h2>

      <div className="container contact__container">
        {/*START OF CONTACT OPTIONS*/}
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMailOutline className='contact__option__icon'/>
            <h4>Email</h4>
            <h5>Email us!</h5>
            <a href="mailto:bncleaningspecialists@gmail.com" className="btn btn-secondary" target="_blank" aria-label="Click here to send us an email.">Click here!</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option__icon'/>
            <h4>FB Messenger</h4>
            <h5>Message us!</h5>
            <a href="https://m.me/bncleaningspecialists"  className="btn btn-secondary" target="_blank" aria-label="Click here to send us a message on facebook.">Click here!</a>
          </article>
          <article className="contact__option">
            <BsTelephone className='contact__option__icon'/>
            <h4>Phone</h4>
            <h5>Call us!</h5>
            <a href="tel:447572777487"  className="btn btn-secondary" target="_blank" aria-label="Click here to call us.">Click here!</a>
          </article>
        </div>
        {/*END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your full name:' required />
          <input type="email" name='email' placeholder='Your email:' required />
          <textarea name="message" rows="7" placeholder='Your message:' required></textarea>
          <button type="submit" className='btn btn-primary' aria-label="Click here to send us a message.">Send message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact