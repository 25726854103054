import React from 'react'
import './nav.css'
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai'
import {BiPhotoAlbum} from 'react-icons/bi'
import {MdOutlineContactPhone} from 'react-icons/md'
import {GiVacuumCleaner} from 'react-icons/gi'

function Nav() {
  
  return (
    <nav>
      <a href="#home" aria-label="Click here to view our home section.">
        <AiOutlineHome /><div className='icon__copy'>Home</div>
      </a>
      <a href="#about" aria-label="Click here to view our about section.">
        <AiOutlineUser /><div className='icon__copy'>About</div>
      </a>
      <a href="#services" aria-label="Click here to view our services section.">
        <GiVacuumCleaner /><div className='icon__copy'>Services</div>
      </a>
      <a href="#portfolio" aria-label="Click here to view our portfolio section.">
        <BiPhotoAlbum /><div className='icon__copy'>Portfolio</div>
      </a>
      <a href="#contact" aria-label="Click here to view our contact section.">
        <MdOutlineContactPhone /><div className='icon__copy'>Contact</div>
      </a>
    </nav>
  )
}

export default Nav