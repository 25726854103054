import React from 'react'
import './footer.css'
import {FaFacebookF, FaTiktok, FaYoutube, FaInstagram} from 'react-icons/fa'

function Footer() {
  return (
    <footer>

      <a href="#" className='footer__logo' aria-label="Click here to view our home section.">B &amp; N Cleaning Specialists</a>

      <ul className="permalinks">
        <li><a href="#" aria-label="Click here to view our home section.">Home</a></li>
        <li><a href="#about" aria-label="Click here to view our about section.">About</a></li>
        <li><a href="#services" aria-label="Click here to view our cleaning services section.">Services</a></li>
        <li><a href="#portfolio" aria-label="Click here to view our portfolio section." >Portfolio</a></li>
        <li><a href="#testimonials" aria-label="Click here to view our testimonials section.">Testimonials</a></li>
        <li><a href="#contact" aria-label="Click here to view our contact section." >Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.instagram.com/bncleaningspecialists/?hl=en-gb" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our cleaning services on instagram."><FaInstagram /></a>
        <a href="https://www.youtube.com/channel/UCcIg39TshWRq9QeH1XEgYjA?app=desktop" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our cleaning services on youtube."><FaYoutube /></a>
        <a href="https://www.facebook.com/bncleaningspecialists" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our cleaning services on facebook."><FaFacebookF /></a>
        <a href="https://www.tiktok.com/@bncleaningspecialists?_t=8Ugs11qZ2Vp&_r=1" target="_blank" rel="noopener noreferrer" aria-label="Click here to view our cleaning services on tiktok."><FaTiktok /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; B &amp; N Cleaning Specialists. All rights reserved.</small>
      </div>

    </footer>
  )
}

export default Footer