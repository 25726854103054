import React from 'react'
import './testimonials.css'
import YELL from '../../assets/yell_icon.jpg'
import GREVIEWS from '../../assets/google_reviews.jpg'
import CTRADE from '../../assets/checkatrade.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [

  {
    name: 'Verified customer',
    review: 'Joe is very friendly, nothing was too much trouble. He cleaned my windows and sills, I dont know how he did it, but they sparkled for weeks. I am due to have them done again. He was clean, and took his time to complete. So pleased I have found him.'
  },
  {
    name: 'Verified customer',
    review: 'B&N cleaned my living room carpets and sofas. Fantastic work done, really happy with the results. The cleaning teams were friendly and very COVID secure!'
  },
  {
    name: 'Verified customer',
    review: 'Just moved in to my new house I, was referred by a friend to B and N cleaning specialists for window cleaning. Their service and professionalism was absolutely brilliant. I would highly recommend them for cleaning purposes.'
  },
  {
    name: 'Verified customer',
    review: 'B&N Cleaning Specialists have been doing a wonderful job keeping our business windows clean! Their service is professional, thorough, speedy and very consistent. Thank you so much for your help!'
  },
  {
    name: 'Verified customer',
    review: 'Joe gave us a good price to have all the carpets in our house cleaned. He was fast and efficient, leaving the carpets practically dried before he left. The stubborn stains we pointed out are hardly there. Amazing job Joe. Thank you. Would certainly recommend.'
  }
  
]

function Testimonials() {
  return (
    <section id='testimonials'>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.map(({name, review}, index) => {
            return (
                <SwiperSlide key={index} className="testimonial">
                  <h5 className='client__name'>{name}</h5>
                  <small className='client__review'>{review}</small>
                </SwiperSlide>
            )
          })
        }
      </Swiper>
      <div className='container third__party__container'>
            <article className='third__party'>
              <div className='third__party__image'>
                <a href="https://www.yell.com/biz/b-and-n-cleaning-specialists-esher-901716922/" target="_blank" rel="noopener noreferrer" aria-label="Click here to view us on yell." >
                  <img src={YELL} alt="Yell.com" />
                </a>
              </div>
            </article>
            <article className='third__party'>
              <div className='third__party__image'>
                <a href="https://www.checkatrade.com/trades/bandncleaningspecialists936446" target="_blank" rel="noopener noreferrer" aria-label="Click here to view us on checkatrade." >
                  <img src={CTRADE} alt="Checkatrade.com" />
                </a>
              </div>
            </article>
            <article className='third__party'>
              <div className='third__party__image'>
                <a href="https://www.google.com/search?q=b+%26+n+cleaning+specialists&sxsrf=ALiCzsYBfdyfZ2iUvIb1lqFZ7i8gOpBIGg%3A1659434421356&ei=tfXoYv-qFeWy8gLFqquwDQ&ved=0ahUKEwi_2dn58qf5AhVlmVwKHUXVCtYQ4dUDCA4&uact=5&oq=b+%26+n+cleaning+specialists&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCCMQsAMQJzIKCAAQRxCwAxDJAzIHCAAQRxCwA0oECEEYAEoECEYYAFAAWABg2gZoAXABeACAAQCIAQCSAQCYAQDIAQPAAQE&sclient=gws-wiz-serp" target="_blank" rel="noopener noreferrer">
                <img src={GREVIEWS} alt="Google.com" aria-label="Click here to view us on google." />
                </a>
              </div>
            </article>
      </div>
    </section>
  )
}

export default Testimonials