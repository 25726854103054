import React from 'react'
import './header.css'
import LOGO from '../../assets/b-and-n-cleaning-specialists-logo.svg'
import HeaderSocials from './HeaderSocials'

function Header() {
  return (
      <header id="home">
       <div className="container header__container">
          <HeaderSocials />
          <div className="header__logo">
            <div className="logo">
              <img src={LOGO} alt="B and N cleaning specialists logo." />
            </div>
            <div className="logo__copy">
              <h1>B &amp; N</h1>
              <h3>CLEANING</h3>
              <h3>SPECIALISTS</h3>
            </div>
          </div>

          <div className="header__copy">
            <h2 className='header__tagline'>We believe&nbsp;in <br /> clean...</h2>
            <p>Our range of cleaning services is large and comprehensive, with satisfaction of our customers being our main priority. We take great pride in offering the highest level of customer service &amp; the same mentality applies to all cleaning services that we provide, to achieve the highest quality of work.</p>
            <p>With the reputation we've become so accustomed to, there hasn't been any cleaning job too big or too small and we will always go above and beyond to ensure 100% customer satisfaction.</p>
            <a href="#contact" className='btn btn-primary'>Get in touch</a>
          </div>  
        </div> 
      </header>
  )
}

export default Header